import { template as template_0c94579dfbc0496cb005c0d97811f8ef } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_0c94579dfbc0496cb005c0d97811f8ef(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
