import { template as template_69805fe95cbd4ffda9e91494af749865 } from "@ember/template-compiler";
const FKLabel = template_69805fe95cbd4ffda9e91494af749865(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
